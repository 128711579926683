import * as React from "react"
import { Dispatch } from "redux"
import FileSaver from "file-saver"

import {
  mapCalculatorAPIToState,
  mapDebugResultIntoTree,
  mapSystemObjectAttributesIntoOptions,
  mapSystemObjectsIntoOptions,
} from "utils/calculators"
import { IAPICalculatorResponse, IStepExport } from "modules/Rules-Pricing/models/calculator"
import { getStepGroupTypes, getSystemObjects } from "services"
import { translations } from "utils/translations"
import { getRules, getSteps, exportStep, getCalculatorById, makeDebug } from "services/calculator"
import { TreeView } from "components"
import { STEP_TABLE_HEADERS } from "constants/tables"
import { getCsvContent } from "utils/csvHelper"
import { ICalculatorState, IGetCalculatorByIdParams, IStepState, ISystemObjectActionCreatorParams } from "./state"
import { uiActions } from "./ui-slice"
import { calculatorActions } from "./calculator-slice"
import { ruleCreatorActions } from "./ruleCreator-slice"

export const fetchSystemObjectOptionsAsync = async (tier: string) => {
  const systemObjectResponse = await getSystemObjects(tier)

  if (!systemObjectResponse?.length) {
    throw new Error(translations.alert.calculator.systemObject.error)
  }

  return systemObjectResponse
}

const fetchSystemObjects = async (
  params: ISystemObjectActionCreatorParams = {} as ISystemObjectActionCreatorParams
) => {
  const { calculatorId, calculatorTier } = params
  const systemObjectResponse = await fetchSystemObjectOptionsAsync(calculatorTier as string)

  const rulesResponse = await getRules(calculatorId)

  const stepsResponse = await getSteps(calculatorId)

  const mappedSystemOptions = mapSystemObjectsIntoOptions(systemObjectResponse, rulesResponse, stepsResponse)

  const mappedSystemAttributesOptions = mapSystemObjectAttributesIntoOptions(
    systemObjectResponse,
    rulesResponse,
    stepsResponse
  )

  return {
    systemObjectOptions: mappedSystemOptions,
    systemObjectAttributeOptions: mappedSystemAttributesOptions,
  }
}

export const fetchSystemObjectOptionsActionCreator = (
  params: ISystemObjectActionCreatorParams = {} as ISystemObjectActionCreatorParams
) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(uiActions.showSpinner())

    try {
      const { systemObjectAttributeOptions, systemObjectOptions } = await fetchSystemObjects(params)

      dispatch(calculatorActions.systemObjectsUpdated(systemObjectOptions))

      dispatch(calculatorActions.systemObjectAttributesUpdated(systemObjectAttributeOptions))
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          children: (error as InstanceType<typeof Error>).toString(),
          severity: "error",
        })
      )
    }
    dispatch(uiActions.hideSpinner())
  }
}

const getCalculatorByIdAsync = async (id: number): Promise<IAPICalculatorResponse> => {
  const calculatorResponse = await getCalculatorById(id)

  if (!calculatorResponse) {
    throw new Error(`Error getting calculator with id${id}`)
  }

  return calculatorResponse
}

export const handleGoingToAddRule = (calculatorState: ICalculatorState, stepState: IStepState) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(ruleCreatorActions.emptyRuleAdded(calculatorState))

    dispatch(calculatorActions.navigateToRuleCreator(stepState))

    dispatch(uiActions.hideDialog())
  }
}

export const getCalculatorByIdActionCreator = (params: IGetCalculatorByIdParams) => {
  const { calculatorId } = params

  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(uiActions.showSpinner())

    try {
      const calculatorResponse = await getCalculatorByIdAsync(calculatorId)

      const calculatorState = mapCalculatorAPIToState(calculatorResponse)

      dispatch(calculatorActions.calculatorUpdated(calculatorState))
    } catch (error) {
      if ((error as InstanceType<typeof Error>).toString() === "Error: Not found") {
        dispatch(calculatorActions.calculatorNotFound())
      } else {
        dispatch(
          uiActions.showNotification({
            children: (error as InstanceType<typeof Error>).toString(),
            severity: "error",
          })
        )
      }
    } finally {
      dispatch(uiActions.hideSpinner())

      dispatch(calculatorActions.calculatorIsNotLoading())
    }
  }
}

export const fetchStepGroupTypesActionCreator = (calculatorId?: number) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(uiActions.showSpinner())

    const fetchStepGroupTypesAsync = async () => {
      const stepGroupTypeResponse = await getStepGroupTypes(calculatorId)

      if (!stepGroupTypeResponse?.length) {
        throw new Error(translations.alert.calculator.systemObject.error)
      }
      return stepGroupTypeResponse
    }

    try {
      const stepGroupTypeResponse = await fetchStepGroupTypesAsync()

      dispatch(calculatorActions.stepGroupTypesUpdated(stepGroupTypeResponse))
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          children: (error as InstanceType<typeof Error>).toString(),
          severity: "error",
        })
      )
    }

    dispatch(uiActions.hideSpinner())
  }
}

export const debugActionCreator = (
  calculatorId: string,
  productId: string,
  customerGroupId: string,
  productName: string
) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(uiActions.showSpinner())

    try {
      const debugResponse = await makeDebug({
        calculatorId,
        productId,
        customerGroupId,
        showAll: false,
      })

      if (!debugResponse) {
        throw new Error(`Error getting debug values`)
      }

      dispatch(
        uiActions.showDialog({
          title: productName,
          primaryActionHandler: () => {
            dispatch(uiActions.hideDialog())
          },
          primaryActionLabel: "Close",
          children: <TreeView title={debugResponse.name} object={mapDebugResultIntoTree(debugResponse)} />,
        })
      )
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          children: (error as InstanceType<typeof Error>).toString(),
          severity: "error",
        })
      )
    }

    dispatch(uiActions.hideSpinner())
  }
}

const exportTable = (data: IStepExport[], stepName?: string) => {
  const csv = data && data?.length > 0 ? getCsvContent(STEP_TABLE_HEADERS, data) : ""

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })

  const fileName = `${stepName || "Rule group"} results`

  FileSaver.saveAs(blob, `${fileName}.csv`)
}

export const exportStepActionCreator = (calculatorId: string, stepId: string, stepName?: string) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(uiActions.showSpinner())

    try {
      const exportResponse = await exportStep(calculatorId, stepId)

      if (!exportResponse) {
        throw new Error(`Error exporting rule group`)
      }

      exportTable(exportResponse, stepName)
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          children: (error as InstanceType<typeof Error>).toString(),
          severity: "error",
        })
      )
    }
    dispatch(uiActions.hideSpinner())
  }
}
