import type { FormikProps } from "formik"
import * as React from "react"
import { useHistory, useParams } from "react-router"
import { useDispatch } from "react-redux"
import { useMutation, useQuery } from "react-query"
import _ from "lodash"
import { ThemeProvider } from "@material-ui/core"
import numeral from "numeral"

import { uiActions } from "store/ui-slice"
import { WizardContainer, WizardTheme, WizardTitle } from "modules/Products/styles"
import { wizardSteps } from "modules/Products/constants/wizard"
import { ROUTES } from "constants/routes"
import { updateProduct, getProductById } from "services"
import { ICustomerGroup } from "modules/Customers/models/customerGroup"
import { getProductFromForm } from "utils/products"
import { IAction } from "models/store"
import {
  IProductStep,
  IProviderStep,
  productStepInitialValues,
  StepAction,
  providerStepInitialValues,
  ICustomerGroupStep,
} from "../models/newProductWizard"
import {
  CustomersAndPricingStep,
  DeleteCustomerGroupDialog,
  ProductStep,
  ProviderStep,
  WizardStepper,
} from "../components"
import { IProductData } from "../models/product"
import { CustomerGroupsModal } from "../components/CustomerGroupsModal"

const providerStepReducer = (prevState: IProviderStep, action: IAction): IProviderStep => {
  if (action.type === StepAction.FORM_CHANGED) {
    return {
      ...prevState,
      ...action.payload,
    }
  }

  return providerStepInitialValues
}

const productStepReducer = (prevState: IProductStep, action: IAction): IProductStep => {
  if (action.type === StepAction.FORM_CHANGED) {
    return {
      ...prevState,
      ...action.payload,
    }
  }

  return productStepInitialValues
}

const UpdateProductWizard = (): JSX.Element => {
  const dispatch = useDispatch()

  const history = useHistory()

  const { productId } = useParams<{ productId: string }>()

  const { isLoading, isSuccess, data } = useQuery("getProductById", () => getProductById(Number(productId)))

  const [state, setState] = React.useState({
    isCustomerGroupsModalOpen: false,
    activeStep: 0,
    selectedCustomerGroups: [] as ICustomerGroup[],
    customerGroupToDelete: (null as unknown) as ICustomerGroup,
  })

  const updater = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }))
  }

  const [providerStepState, dispatchProviderStep] = React.useReducer(providerStepReducer, providerStepInitialValues)

  const [productStepState, dispatchProductStep] = React.useReducer(productStepReducer, productStepInitialValues)

  const { mutate } = useMutation((productData: IProductData) => updateProduct(Number(productId), productData), {
    onSuccess: () => {
      history.push(ROUTES.PRODUCTS.path)
    },
    onError: (error: string) => {
      dispatch(
        uiActions.showNotification({
          children: error.toString(),
          severity: "error",
        })
      )
    },
  })

  const handleNext = (): void => {
    if (state.activeStep === 2) {
      const payload: IProductData = {
        ...getProductFromForm(productStepState, providerStepState),
        customerGroups: _.map(state.selectedCustomerGroups, item => ({
          id: item.id,
        })),
      }

      mutate(payload)

      return
    }

    updater({ activeStep: state.activeStep + 1 })
  }

  const handleBack = () => {
    updater({ activeStep: state.activeStep - 1 })
  }

  const formikRef = React.useRef({} as FormikProps<unknown>)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    formikRef.current?.handleSubmit(event)
  }

  const handleCustomerGroupsSelected = (selectedCustomerGroups: ICustomerGroup[]) => {
    updater({ selectedCustomerGroups, isCustomerGroupsModalOpen: false })
  }

  const handleRemoveCustomerGroups = (customerGroup: ICustomerGroup) => {
    updater({ customerGroupToDelete: customerGroup })
  }

  const handleCustomerGroup = () => {
    const newCustomerGroups = state.selectedCustomerGroups.filter(item => item.id !== state.customerGroupToDelete?.id)

    updater({
      selectedCustomerGroups: newCustomerGroups,
      customerGroupToDelete: undefined,
    })
  }

  React.useEffect(() => {
    if (!isLoading && data && formikRef.current) {
      const providerStepData: IProviderStep = {
        supplierName: data.supplierName,
        supplierProductId: data.supplierProdId,
        manufacturer: data.manufacturer,
        isStepValid: true,
      }

      const marginFocused = numeral(data.marginFocused || 0).multiply(100)

      const productStepData: IProductStep = {
        productName: data.name,
        vsId: data.vsId,
        map: data.map || 0,
        majorCategoryName: data.majorCategoryName,
        categoryName: data.categoryName || "",
        vipMarkerName: data.vipMarkerName || "",
        feeStrategy: data.feeStrategy,
        marsFeeStrategy: data.marsFeeStrategy || "",
        srpRuleName: data.srpRuleName || "",
        banFeeStrategy: data.banFeeStrategy || "",
        vcost: data.vcost,
        vetList: data.vetList || 0,
        exception: data.exception || "",
        rxrequired: data.rxrequired,
        weight: data.weight || 0,
        purchaseSize: data.purchaseSize,
        productSize: data.size,
        packaging: data.packaging,
        breakdown: data.breakdown,
        instantrebate: data.instantrebate || 0,
        isStepValid: true,
        marginFocused: marginFocused.value() || 0,
        equineFee: data.equineFee,
        equineRetailMarkUp: data.equineRetailMarkUp,
      }

      formikRef.current.setValues(providerStepData)

      dispatchProviderStep({
        type: StepAction.FORM_CHANGED,
        payload: providerStepData,
      })

      dispatchProductStep({
        type: StepAction.FORM_CHANGED,
        payload: productStepData,
      })

      updater({ selectedCustomerGroups: data.customerGroups })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data])

  const isCustomerGroupsActive = isSuccess && providerStepState.isStepValid && productStepState.isStepValid

  const children = {
    1: (
      <ProviderStep
        ref={formikRef as React.MutableRefObject<FormikProps<IProviderStep>>}
        handleNext={handleNext}
        handleBack={handleBack}
        dispatchProviderStep={dispatchProviderStep}
        providerStepState={providerStepState}
      />
    ),
    2: (
      <ProductStep
        ref={formikRef as React.MutableRefObject<FormikProps<IProductStep>>}
        handleNext={handleNext}
        handleBack={handleBack}
        dispatchProductStep={dispatchProductStep}
        productStepState={productStepState}
      />
    ),
    3: (
      <CustomersAndPricingStep
        ref={formikRef as React.MutableRefObject<FormikProps<ICustomerGroupStep>>}
        handleNext={handleNext}
        handleBack={handleBack}
        customerStepState={state.selectedCustomerGroups}
        removeItemHandler={handleRemoveCustomerGroups}
        addGroupButtonHandler={() => {
          updater({ isCustomerGroupsModalOpen: true })
        }}
      />
    ),
  }

  return (
    <ThemeProvider theme={WizardTheme}>
      <WizardTitle variant="h2">Update Product</WizardTitle>
      {!isLoading && isCustomerGroupsActive && (
        <CustomerGroupsModal
          handleClose={() => updater({ isCustomerGroupsModalOpen: false })}
          handleAccept={handleCustomerGroupsSelected}
          isOpen={state.isCustomerGroupsModalOpen}
          initialCustomerGroups={state.selectedCustomerGroups}
        />
      )}
      <DeleteCustomerGroupDialog
        onClose={() => updater({ customerGroupToDelete: undefined })}
        isOpen={Boolean(state.customerGroupToDelete)}
        confirmHandler={handleCustomerGroup}
        customerGroup={state.customerGroupToDelete}
      />
      <form data-testid="wizard" onSubmit={handleSubmit}>
        <WizardContainer disabled={isLoading}>
          <WizardStepper activeStep={state.activeStep} steps={wizardSteps} />
          {children[wizardSteps[state.activeStep].id as keyof typeof children]}
        </WizardContainer>
      </form>
    </ThemeProvider>
  )
}
export { UpdateProductWizard }
