import type { GridCellParams, GridCellValue, GridColDef, GridRenderCellParams, GridRowData } from "@mui/x-data-grid"
import * as React from "react"
import _ from "lodash"
import {
  centerColumn,
  formatPrice,
  leftEditableColumn,
  leftNonEditableColumn,
  leftNonEditableTooltipColumn,
  priceFormatterParser,
  calculatorResultPriceFormatterParser,
} from "modules/TableGlobalStyles"
import { renderAssertPriceEditInputCell } from "modules/Parameters/components/PriceInput"
import type { IStepGroupType } from "modules/Rules-Pricing/models/calculator"
import { CalculatorResultsActions } from "../containers/CalculatorResultsActions"

const AtypicalChildren = ({ row, field, value }: { value: GridCellValue; row: GridRowData; field: string }) => {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const container = ref.current?.parentElement

    const isAtypical = row[`atypical${field.charAt(0).toUpperCase()}${field.slice(1)}`]

    const isError = !_.isNil(row[field]) && _.isNaN(Number(row[field]))

    if (container && isAtypical) {
      container.style.backgroundColor = "#ffcccc"
    }

    if (container && isError) {
      container.style.backgroundColor = "#fbecec"
    }
  }, [row, field])

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
      }}
    >
      {value}
    </div>
  )
}

const renderAtypicalCell = ({ formattedValue, field, row }: GridRenderCellParams): JSX.Element => {
  return <AtypicalChildren field={field} row={row} value={formattedValue} />
}

const getExcelPricingColumns = (pricingColumns: GridColDef[]) => {
  return pricingColumns.map(({ field, headerName }) => {
    const column: GridColDef = {
      field: `excel${field.charAt(0).toUpperCase()}${field.slice(1)}`,
      headerName,
      hide: true,
      valueFormatter: params => formatPrice(params, true, null),
    }

    return column
  })
}

const getColumns = (stepGroupTypes: IStepGroupType[]) => {
  const pricingColumns: GridColDef[] = stepGroupTypes.map(stepGroupType => {
    return {
      field: stepGroupType.name,
      headerName: stepGroupType.displayName,
      type: "number",
      width: 150,
      sortable: false,
      filterable: false,
      ...leftEditableColumn,
      ...calculatorResultPriceFormatterParser,
      renderCell: renderAtypicalCell,
      renderEditCell: props => renderAssertPriceEditInputCell(props, "productIds"),
      cellClassName: "step-group-cell",
      disableExport: true,
    }
  })

  const columns: GridColDef[] = [
    {
      field: "productIds",
      headerName: "VS ID",
      type: "string",
      width: 120,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "name",
      headerName: "Product Name",
      type: "string",
      width: 190,
      sortable: false,
      filterable: false,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "customerGroups",
      headerName: "Customer Group",
      type: "string",
      width: 170,
      sortable: false,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "vcost",
      headerName: "VS Price",
      type: "number",
      ...leftNonEditableColumn,
      width: 120,
      sortable: false,
      filterable: false,
      ...priceFormatterParser,
    },
    ...pricingColumns,
    ...getExcelPricingColumns(pricingColumns),
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      type: "string",
      width: 150,
      sortable: false,
      filterable: false,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "majorCategoryName",
      headerName: "Major Category",
      type: "string",
      width: 180,
      sortable: false,
      filterable: false,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "actions",
      headerName: "Actions",
      ...centerColumn,
      width: 110,
      renderCell: ({ row }: GridCellParams): JSX.Element => {
        return <CalculatorResultsActions row={row} />
      },
    },
  ]

  return columns
}

export { getColumns }
