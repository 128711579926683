import React, { useEffect, useMemo, useState } from "react"
import {
  Table,
  Header,
  ButtonsGroup,
  TableToolbar,
  TableLoadingOverlay,
  DropzoneDialog,
  Tabs,
  IObjectTab,
  TabPanel,
  Button,
} from "components"
import { useQuery, useMutation, useQueryClient } from "react-query"
import _ from "lodash"
import {
  getCompetitorsProducts,
  getCompetitors,
  updateCompetitorsProducts,
  auditCompetitors,
} from "services/competitors"
import { getUploadStatus } from "services/bulk"
import { GridRowData, GridColDef, GridEditRowsModel } from "@mui/x-data-grid"
import { uiActions } from "store/ui-slice"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store"
import { csvToJson, setAlertContent } from "utils/general"
import { Box, Tab } from "@material-ui/core"
import { getDynamicColumns, getDynamicRows } from "utils/competitors"
import { competitorsColumns } from "modules/Competitors/constants/competitorsTable"
import { bulkActions } from "store/bulk-slice"
import { BULK_STATUS, TABLE } from "constants/bulk"
import { getCsvContent } from "utils/csvHelper"
import FileSaver from "file-saver"
import { TooltipWrapperButton } from "components/Table/styles"
import { CloudDownload } from "@material-ui/icons"
import { AUDIT_TABLE_HEADERS } from "constants/tables"
import { useAuditLog } from "hooks/use-audit"
import { ENV } from "constants/env"
import { useHasReadWriteRole } from "utils/user"
import { translations } from "utils/translations"
import type { IBulkResponse, ICompetitorsPrice, ICompetitorsProduct, IInputCSV } from "../models/competitor"
import useUpdateCompetitorProduct from "./CompetitorsList.utils"

export const CompetitorsList = (): JSX.Element => {
  const userHasReadWriteRole = useHasReadWriteRole()

  const bulkId = localStorage.getItem("bulk_id") ?? ""
  const bulkStatus = useSelector((state: RootState) => state.bulk.status)

  const queryClient = useQueryClient()

  const dispatch = useDispatch()
  const [dropzoneIsOpen, setDropzoneIsOpen] = useState(false)

  const [competitorsFiles, setCompetitorsFiles] = useState<File[]>([])

  const [csvConverted, setCsvConverted] = useState<IInputCSV[]>([])

  const [tabValue, setTabValue] = useState(0)

  const [editRowsModel, setEditRowsModel] = React.useState({})

  const { getAuditLog } = useAuditLog({
    getAuditLogService: auditCompetitors,
    tableHeaders: AUDIT_TABLE_HEADERS,
    fileName: "Competitors Audit Log",
  })

  const { isLoading: getCompetitorsProductsIsLoading, data: getCompetitorsProductsData } = useQuery(
    ["getCompetitorsProducts"],
    () => getCompetitorsProducts()
  )

  const { useUpdateCompetitorProductMutate } = useUpdateCompetitorProduct(
    getCompetitorsProductsData as ICompetitorsProduct[]
  )

  const { isLoading: getCompetitorsIsLoading, data: getCompetitorsData } = useQuery(["getCompetitors"], () =>
    getCompetitors()
  )

  const { mutate } = useMutation((inputCSV: IInputCSV[]) => updateCompetitorsProducts(inputCSV), {
    onSuccess: (data: IBulkResponse) => {
      if (data) {
        localStorage.setItem("bulk_id", data?.id)
        dispatch(bulkActions.updateStatus(data?.status))
        const { children, severity } = setAlertContent(bulkStatus)
        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )
      }
    },
    onError: () => {
      const { children, severity } = setAlertContent(BULK_STATUS.FAILED)
      dispatch(
        uiActions.showNotification({
          children,
          severity,
        })
      )
    },
  })

  const {
    data: getUploadStatusData,
  }: {
    isLoading: boolean
    data?: IBulkResponse
  } = useQuery(["getBulkCalculationStatus", bulkId], () => getUploadStatus(bulkId), {
    enabled: !!bulkId,
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    onSuccess: () => {
      if (getUploadStatusData) {
        dispatch(bulkActions.updateStatus(getUploadStatusData?.status))
      }
      if (getUploadStatusData?.status === BULK_STATUS.PROCESSED) {
        dispatch(bulkActions.updateStatus(getUploadStatusData?.status))
        const { children, severity } = setAlertContent(bulkStatus)
        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )
        queryClient.invalidateQueries("getCompetitorsProducts")
        setTimeout(() => {
          localStorage.removeItem("bulk_id")
          dispatch(bulkActions.clearStatus())
        }, 2000)
      }
    },
    onError: () => {
      dispatch(bulkActions.updateStatus(BULK_STATUS.FAILED))
      localStorage.removeItem("bulk_id")
      const { children, severity } = setAlertContent(bulkStatus)
      dispatch(
        uiActions.showNotification({
          children,
          severity,
        })
      )
      localStorage.removeItem("bulk_id")
      dispatch(bulkActions.clearStatus())
    },
  })

  useEffect(() => {
    const mapCsvToJson = async () => {
      const textCompetitorsFile = await competitorsFiles[0].text()
      try {
        const json = csvToJson(TABLE.COMPETITORS, textCompetitorsFile)
        setCsvConverted(json)
      } catch (e) {
        dispatch(
          uiActions.showNotification({
            children: e.message,
            severity: "error",
          })
        )
      }
    }
    if (competitorsFiles.length) {
      mapCsvToJson()
    }
  }, [competitorsFiles, dispatch])

  useEffect(() => {
    if (csvConverted.length) {
      mutate(csvConverted)
    }
  }, [csvConverted, mutate])

  const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setTabValue(newValue)
  }

  const competitorsProductsColumns = useMemo(() => getDynamicColumns(getCompetitorsProductsData), [
    getCompetitorsProductsData,
  ])

  const competitorsProductsRows = useMemo(() => getDynamicRows(getCompetitorsProductsData), [
    getCompetitorsProductsData,
  ])

  const getExportData = () => {
    const headers = getCompetitorsData?.map(comp => comp.name)
    const resultData: any[] = []
    getCompetitorsProductsData?.forEach(cpd => {
      let obj: any = { VS_ID: cpd.vsId }
      headers?.forEach(header => {
        cpd.competitors.forEach(cp => {
          if (header === cp.name) {
            obj = { ...obj, [header]: cp.price }
          }
        })
      })
      headers?.forEach(header => {
        if (!obj[header]) {
          obj = { ...obj, [header]: " - " }
        }
      })
      resultData.push(obj)
    })
    return resultData
  }

  const exportTable = async () => {
    dispatch(uiActions.showSpinner())
    const data = getExportData()
    const headers =
      data &&
      data[0] &&
      Object.keys(data[0]).map(key => {
        return { header: key, accessor: key }
      })
    const csv = data && data.length > 0 ? getCsvContent(headers, data) : ""
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    dispatch(uiActions.hideSpinner())
    FileSaver.saveAs(blob, "List of Competitors.csv")
  }

  React.useEffect(() => {
    if (competitorsProductsRows.length) {
      dispatch(
        uiActions.showNotification({
          children: translations.general.valuesEditable,
          severity: "info",
        })
      )
    }
  }, [dispatch, competitorsProductsRows.length])

  const updateCompetitorProductMutate = useUpdateCompetitorProductMutate

  const handleEditRowsModelChange = React.useCallback(
    (model: GridEditRowsModel) => {
      const [rowId] = Object.keys(model)

      const field = model[rowId]

      if (field) {
        const [fieldName] = Object.keys(field)

        const updatedValue = (field[fieldName].value || "0") as string

        const isUpdatedValueValid = !_.isNaN(updatedValue)

        if (isUpdatedValueValid) {
          const competitorProduct = {
            id: parseInt(rowId, 10),
            name: fieldName.toString(),
            price: updatedValue.toString(),
          } as ICompetitorsPrice

          updateCompetitorProductMutate(competitorProduct)
        }
      }

      setEditRowsModel(model)
    },
    [updateCompetitorProductMutate]
  )

  const options = [
    {
      label: "Import Competitors",
      action: () => setDropzoneIsOpen(true),
      disabled: !userHasReadWriteRole,
    },
    {
      label: "Export Competitors",
      action: () => exportTable(),
      disabled: !userHasReadWriteRole,
    },
  ]

  const columns = getCompetitorsProductsIsLoading ? ([] as GridColDef[]) : competitorsProductsColumns

  const competitorsTab: IObjectTab[] = [
    {
      id: 0,
      label: "Products",
      children: (
        <Table
          rows={competitorsProductsRows}
          columns={columns.map(column => ({
            ...column,
            editable:
              column.field !== "SKU" &&
              column.field !== "name" &&
              column.field !== "averagePrice" &&
              userHasReadWriteRole,
          }))}
          loading={getCompetitorsProductsIsLoading}
          components={{
            Toolbar: () => <TableToolbar showColumnsButton />,
            LoadingOverlay: TableLoadingOverlay,
          }}
          pagination
          hideFooterPagination={!competitorsProductsRows.length}
          autoPageSize={false}
          pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditRowsModelChange}
        />
      ),
    },
    {
      id: 1,
      label: "Competitors",
      children: (
        <Table
          rows={getCompetitorsData as GridRowData[]}
          columns={competitorsColumns}
          loading={getCompetitorsIsLoading}
          components={{
            Toolbar: () => (
              <Box display="flex">
                <TableToolbar showColumnsButton />
                <TooltipWrapperButton>
                  <Button variant="text" onClick={getAuditLog} icon={<CloudDownload />} size="small">
                    Download competitors audit log
                  </Button>
                </TooltipWrapperButton>
              </Box>
            ),
            LoadingOverlay: TableLoadingOverlay,
          }}
          pagination
          autoPageSize={false}
          pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
        />
      ),
    },
  ]

  return (
    <>
      <Box>
        <Header title="List of Competitors" actions={<ButtonsGroup options={options} />} />
        <Tabs value={tabValue} onChange={handleChange}>
          {competitorsTab.map((tab: IObjectTab) => [<Tab key={tab.id} label={tab.label} disableRipple />])}
        </Tabs>
      </Box>
      <DropzoneDialog
        open={dropzoneIsOpen}
        onSave={files => {
          setCompetitorsFiles(files)
          setDropzoneIsOpen(false)
        }}
        acceptedFiles={["text/csv", ".csv"]}
        showPreviews
        onClose={() => setDropzoneIsOpen(false)}
        initialFiles={competitorsFiles}
        dialogTitle="Import Competitors"
        submitButtonText="Import"
      />
      {competitorsTab.map((tab: IObjectTab) => [
        <TabPanel key={`panel-${tab.id}`} value={tabValue} index={tab.id}>
          {tab.children}
        </TabPanel>,
      ])}
    </>
  )
}
